<template>
  <footer class="footer">
    <div class="contact">
      <div class="item">
        <img src="../assets/homeImg/phone.png" alt="" />
        <span>咨询电话：18137835079</span>
      </div>
      <div class="item">
        <img src="../assets/homeImg/email.png" alt="" />
        <span>邮箱：zxb@zuoshang2019.com</span>
      </div>
      <div class="item">
        <img src="../assets/homeImg/address.png" alt="" />
        <span>河南省郑州市金水区正东龙润国际7楼721室</span>
      </div>
    </div>
    <div class="line"></div>
    <div class="nav_list">
      <router-link to="/">首页</router-link>
      <span>/</span>
      <router-link to="/smartFactory">智慧工厂</router-link>
      <span>/</span>
      <router-link to="/smartCity">智慧城市</router-link>
      <span>/</span>
      <router-link to="/about">关于我们</router-link>
      <span>/</span>
      <router-link to="/contactUs">联系我们</router-link>
    </div>
    <div class="copy">
      <div class="copyright">A​​​​​​​​​​​​​​​​​​Copyright©zuoshangwangluo. All Rights Reserved</div>
      <a href="https://beian.miit.gov.cn/#/Integrated/index" target="_blank">
        备案号：豫ICP备19044176号-1</a
      >
    </div>
  </footer>
</template>

<script>
export default {
  name: "Footer",
  data() {
    return {
      active: 0,
    };
  },
  methods: {
    onChange(index) {
      this.active = index;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.footer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 340px;
  background: #F2F5F9;
  .contact {
    width: 1200px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      img {
        width: 48px;
        height: 48px;
        margin-right: 18px;
      }
      span {
        font-size: 16px;
        color: #333333;
      }
    }
  }

  .line {
    width: 1200px;
    height: 1px;
    background: rgba(73, 87, 112, 0.2);
    margin: 0 auto;
    margin-top: 38px;
  }
  .nav_list {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    color: #333333;
    margin-top: 60px;
    a {
      text-decoration: none;
    }
    span {
      margin: 0 30px;
    }
  }
  .copy {
    color: #333333;
    font-size: 18px;
    text-align: center;
    // margin: 26px 0;
    margin-top: 26px;
    .copyright {
      margin-bottom: 15px;
    }
    a {
      text-decoration: none;
      color: #333333;
      font-size: 18px;
    }
  }
}
</style>
