import Vue from 'vue'
import App from './App.vue'
import '@/icons'
// import store from './store'
//引用router.js
import router from './router.js'
import VueLazyLoad from 'vue-lazyload'
Vue.use(VueLazyLoad, {
  preLoad: 1,
  // error: require('./assets/imgs/error.jpg'),
  // loading: require('./assets/imgs/homePage_top.jpg'),
  attempt: 1,
})

// import 'fullpage.js/vendors/scrolloverflow' // Optional. When using scrollOverflow:true
// import VueFullPage from 'vue-fullpage.js'
// import VueFullpage from 'vue-fullpage.js';
// Vue.use(VueFullpage);
// Vue.use(VueFullPage);


Vue.config.productionTip = false

new Vue({
  render: h => h(App),
  router,
}).$mount('#app')
