<template>
  <header>
    <div id="menuActive" class="header active">
      <!-- <svg-icon icon-class="refresh" /> -->
      <!-- <img src="../assets/imgs/12-0001.jpg" alt="" />
    <img src="../assets/imgs/b (1).jpg" alt="" />
    <img src="../assets/imgs/b (1).png" alt="" />
    <img src="../assets/imgs/b (10).jpg" alt="" />
    <img src="../assets/imgs/b (12).jpg" alt="" />
    <img src="../assets/imgs/b (11).jpg" alt="" />
    <img src="../assets/imgs/b (15).jpg" alt="" /> -->
      <!-- <div id="activeBg"></div> -->
      <div class="nav_content">
        <h1>
          <router-link to="/">
            <img
              class="logo"
              src="../assets/homeImg/logo_white.png"
              alt="佐尚网络"
              title="佐尚网络"
            />
            <!-- <img
              v-else
              class="logo"
              src="../assets/homeImg/logo.png"
              alt="佐尚网络"
              title="佐尚网络"
            /> -->
          </router-link>
        </h1>
        <ul class="nav_list">
          <li
            class="nav"
            :class="active == 0 ? 'active' : ''"
            @mouseenter="onMouseenter(0)"
            @mouseleave="onMouseleave(0)"
          >
            <!-- <span>官网首页</span> -->
            <router-link to="/" :class="mouseenter == 0 ? 'mouseenter' : ''">
              <div class="text_content">
                <span>首页</span>
                <div class="line" :class="mouseleave == 0 ? 'mouseleave' : ''"></div>
              </div>
            </router-link>
          </li>
          <li
            class="nav"
            :class="active == 1 ? 'active' : ''"
            @mouseenter="onMouseenter(1)"
            @mouseleave="onMouseleave(1)"
          >
            <!-- <span>集团介绍</span> -->
            <router-link to="/smartFactory" :class="mouseenter == 1 ? 'mouseenter' : ''">
              <div class="text_content">
                <span>智慧工厂</span>
                <div class="line" :class="mouseleave == 1 ? 'mouseleave' : ''"></div>
              </div>
            </router-link>
            <div v-show="showChildList&&isChange" class="child_list">
              <div class="list_main">
                <div class="item" @click="jump(0)">能源管理解决方案</div>
                <div class="item" @click="jump(1)">砂石骨料一卡通</div>
                <div class="item" @click="jump(2)">设备管理解决方案</div>
                <div class="item" @click="jump(3)">车辆调度解决方案</div>
              </div>
            </div>
          </li>
          <li
            class="nav"
            :class="active == 2 ? 'active' : ''"
            @mouseenter="onMouseenter(2)"
            @mouseleave="onMouseleave(2)"
          >
            <!-- <span>老家礼物</span> -->
            <router-link to="/smartCity" :class="mouseenter == 2 ? 'mouseenter' : ''">
              <div class="text_content">
                <span>智慧城市</span>
                <div class="line" :class="mouseleave == 2 ? 'mouseleave' : ''"></div>
              </div>
            </router-link>
            <div v-show="showChildList1&&isChange" class="child_list">
              <div class="list_main">
                <div class="item" @click="jump1(0)">智慧停车场解决方案</div>
                <div class="item" @click="jump1(1)">智能监管解决方案</div>
                <div class="item" @click="jump1(2)">城市油烟监管</div>
              </div>
            </div>
          </li>
          <li
            class="nav"
            :class="active == 3 ? 'active' : ''"
            @mouseenter="onMouseenter(3)"
            @mouseleave="onMouseleave(3)"
          >
            <!-- <span>媒体中心</span> -->
            <router-link to="/about" :class="mouseenter == 3 ? 'mouseenter' : ''">
              <!-- <span>媒体中心</span> -->
              <div class="text_content">
                <span>关于我们</span>
                <div class="line" :class="mouseleave == 3 ? 'mouseleave' : ''"></div>
              </div>
            </router-link>
            <!-- <div v-show="showChildList2" class="child_list">
            <div class="blank"></div>
            <div class="list_main">
              <div class="item" @click="jump2(0)">企业动态</div>
              <div class="item" @click="jump2(1)">新闻报道</div>
            </div>
          </div> -->
          </li>
          <li
            class="nav"
            :class="active == 4 ? 'active' : ''"
            @mouseenter="onMouseenter(4)"
            @mouseleave="onMouseleave(4)"
          >
            <!-- <span>招商合作</span> -->
            <router-link to="/contactUs" :class="mouseenter == 4 ? 'mouseenter' : ''">
              <!-- <span>招商合作</span> -->
              <div class="text_content">
                <span>联系我们</span>
                <div class="line" :class="mouseleave == 4 ? 'mouseleave' : ''"></div>
              </div>
            </router-link>
          </li>
        </ul>
      </div>
    </div>
    <div id="menu" class="header">
      <!-- <svg-icon icon-class="refresh" /> -->
      <!-- <img src="../assets/imgs/12-0001.jpg" alt="" />
    <img src="../assets/imgs/b (1).jpg" alt="" />
    <img src="../assets/imgs/b (1).png" alt="" />
    <img src="../assets/imgs/b (10).jpg" alt="" />
    <img src="../assets/imgs/b (12).jpg" alt="" />
    <img src="../assets/imgs/b (11).jpg" alt="" />
    <img src="../assets/imgs/b (15).jpg" alt="" /> -->
      <!-- <div id="activeBg"></div> -->
      <div class="nav_content">
        <h1>
          <!-- <router-link to="/">
            <img
              v-if="isChange"
              class="logo"
              src="../assets/homeImg/logo_white.png"
              alt="佐尚网络"
              title="佐尚网络"
            /> -->
            <img
              class="logo"
              src="../assets/homeImg/logo.png"
              alt="佐尚网络"
              title="佐尚网络"
            />
          </router-link>
        </h1>
        <ul class="nav_list">
          <li
            class="nav"
            :class="active == 0 ? 'active' : ''"
            @mouseenter="onMouseenter(0)"
            @mouseleave="onMouseleave(0)"
          >
            <!-- <span>官网首页</span> -->
            <router-link to="/" :class="mouseenter == 0 ? 'mouseenter' : ''">
              <div class="text_content">
                <span>首页</span>
                <div class="line" :class="mouseleave == 0 ? 'mouseleave' : ''"></div>
              </div>
            </router-link>
          </li>
          <li
            class="nav"
            :class="active == 1 ? 'active' : ''"
            @mouseenter="onMouseenter(1)"
            @mouseleave="onMouseleave(1)"
          >
            <!-- <span>集团介绍</span> -->
            <router-link to="/smartFactory" :class="mouseenter == 1 ? 'mouseenter' : ''">
              <div class="text_content">
                <span>智慧工厂</span>
                <div class="line" :class="mouseleave == 1 ? 'mouseleave' : ''"></div>
              </div>
            </router-link>
            <div v-show="showChildList&&!isChange" class="child_list">
              <div class="list_main">
                <div class="item" @click="jump(0)">能源管理解决方案</div>
                <div class="item" @click="jump(1)">砂石骨料一卡通</div>
                <div class="item" @click="jump(2)">设备管理解决方案</div>
                <div class="item" @click="jump(3)">车辆调度解决方案</div>
              </div>
            </div>
          </li>
          <li
            class="nav"
            :class="active == 2 ? 'active' : ''"
            @mouseenter="onMouseenter(2)"
            @mouseleave="onMouseleave(2)"
          >
            <!-- <span>老家礼物</span> -->
            <router-link to="/smartCity" :class="mouseenter == 2 ? 'mouseenter' : ''">
              <div class="text_content">
                <span>智慧城市</span>
                <div class="line" :class="mouseleave == 2 ? 'mouseleave' : ''"></div>
              </div>
            </router-link>
            <div v-show="showChildList1&&!isChange" class="child_list">
              <div class="list_main">
                <div class="item" @click="jump1(0)">智慧停车场解决方案</div>
                <div class="item" @click="jump1(1)">智能监管解决方案</div>
                <div class="item" @click="jump1(2)">城市油烟监管</div>
              </div>
            </div>
          </li>
          <li
            class="nav"
            :class="active == 3 ? 'active' : ''"
            @mouseenter="onMouseenter(3)"
            @mouseleave="onMouseleave(3)"
          >
            <!-- <span>媒体中心</span> -->
            <router-link to="/about" :class="mouseenter == 3 ? 'mouseenter' : ''">
              <!-- <span>媒体中心</span> -->
              <div class="text_content">
                <span>关于我们</span>
                <div class="line" :class="mouseleave == 3 ? 'mouseleave' : ''"></div>
              </div>
            </router-link>
            <!-- <div v-show="showChildList2" class="child_list">
            <div class="blank"></div>
            <div class="list_main">
              <div class="item" @click="jump2(0)">企业动态</div>
              <div class="item" @click="jump2(1)">新闻报道</div>
            </div>
          </div> -->
          </li>
          <li
            class="nav"
            :class="active == 4 ? 'active' : ''"
            @mouseenter="onMouseenter(4)"
            @mouseleave="onMouseleave(4)"
          >
            <!-- <span>招商合作</span> -->
            <router-link to="/contactUs" :class="mouseenter == 4 ? 'mouseenter' : ''">
              <!-- <span>招商合作</span> -->
              <div class="text_content">
                <span>联系我们</span>
                <div class="line" :class="mouseleave == 4 ? 'mouseleave' : ''"></div>
              </div>
            </router-link>
          </li>
        </ul>
      </div>
    </div>
  </header>
</template>

<script>
import $ from "jquery";
export default {
  name: "Header",
  // props: {
  //   isChange: {
  //     type: Boolean,
  //     default: false,
  //   },
  // },
  data() {
    return {
      active: 0,
      ready: false,
      mouseenter: -1,
      mouseleave: -1,
      isFirst: true,
      showChildList: false,
      showChildList1: false,
      showChildList2: false,
      showChildList3: false,
      seriesList: [],
      isChange: false,
    };
  },
  // watch: {
  //   isChange(val) {
  //     console.log(val);
  //   },
  // },
  mounted() {
    var self = this;
    this.$nextTick(() => {
      setTimeout(() => {
        // //参数说明
        // //sign：公司渠道唯一标识，复制即可，无需改动
        // //uid：用户唯一标识，如果没有则不填写，默认为空
        // //data：用于传递用户信息，最多支持5个，参数名分别为c1,c2,c3,c4,c5；默认为空
        // //selector：css选择器(document.querySelector, 如#btnid .chat-btn等)，用于替换默认的常驻客服入口
        // //callback(type, data): 回调函数,type表示事件类型， data表示事件相关数据
        // //type支持的类型：newmsg有新消息，error云智服页面发生错误， close聊天窗口关闭
        // window.yzf &&
        //   window.yzf.init({
        //     sign:
        //       "37ef9b97d27c02c120449fed1db4b86ad35cf1936152b2797ddedb7850f98be4b8d5d953e5965cead13bfe5093f550aff150a47a",
        //     uid: "",
        //     data: {
        //       c1: "",
        //       c2: "",
        //       c3: "",
        //       c4: "",
        //       c5: "",
        //     },
        //     selector: "",
        //     callback: function (type, data) {},
        //   });
        //window.yzf.close() 关闭1已打开的回话窗口
        var stStart = $(window).scrollTop() + 74;
        var target = 100;
        if (stStart > target) {
          self.isChange = true;
          $("#menu").stop().animate({top:"-100px"},0);
            $("#menuActive").stop().animate({ top:"0" });
        } else {
          self.isChange = false;
          $("#menuActive").stop().animate({top:"-100px"},0);
            $("#menu").stop().animate({ top: "0" });
        }
        // console.log(target);
        $(window).scroll(function () {
          var st = $(window).scrollTop() + 74;

          if (st > target) {
            // $('#menu').addClass('active')
            self.isChange = true;
            $("#menu").stop().animate({top:"-100px"},0);
            $("#menuActive").stop().animate({ top:"0" });

            // console.log(self.isChange);
          } else {
            self.isChange = false; 
            $("#menuActive").stop().animate({top:"-100px"},0);
            $("#menu").stop().animate({ top: "0" });
            // $(".header").css({top:"-100px"});
            // $(".header").animate({top:"0"});
            // $(".header").animate({top:"-100px"});
            // $('#menu').removeClass('active')
            // $(".header.active").animate({
            //   top: -100,
            // });

            // console.log(self.isChange);
          }
        });
      }, 100);
    });
    console.log(this.isChange);
    let _this = this;
    this.ready = false;
    // setTimeout(function () {
    var name = _this.$route.name;
    console.log(name);
    if (name == "Home") {
      _this.active = 0;
    } else if (name == "SmartFactory") {
      _this.active = 1;
    } else if (name == "SmartCity") {
      _this.active = 2;
    } else if (name == "About") {
      _this.active = 3;
    } else if (name == "ContactUs") {
      _this.active = 4;
    }
    _this.ready = true;
    // }, 100);
    // this.getSeriesList();
  },
  // created() {
  //   console.log("created");
  //   var name = this.$route.name;
  //   console.log(name);
  // },
  methods: {
    jump(data) {
      this.showChildList = false;
      var name = this.$route.name;
      if (name != "SmartFactory") {
        this.$router.push({
          path: "/smartFactory",
          query: { data: data },
        });
      } else {
        console.log(data);
        this.$emit("link-jump", data);
      }
    },
    jump1(data) {
      this.showChildList1 = false;
      var name = this.$route.name;
      if (name != "SmartCity") {
        this.$router.push({
          path: "/smartCity",
          query: { data: data },
        });
      } else {
        console.log(data);
        // if(this.isIE()){
        //   this.$emit("ie-jump", data);
        // }
        // else{
        //   this.$emit("link-jump1", data);
        // }
        this.$emit("link-jump", data);
      }
    },
    onMouseenter(index) {
      if (index == this.mouseleave) {
        this.mouseleave = -1;
      }
      this.mouseenter = index;
      if (index == 1) {
        this.showChildList = true;
      } else {
        this.showChildList = false;
      }
      if (index == 2) {
        this.showChildList1 = true;
      } else {
        this.showChildList1 = false;
      }
      if (index == 3) {
        this.showChildList2 = true;
      } else {
        this.showChildList2 = false;
      }
      if (index == 5) {
        this.showChildList3 = true;
      } else {
        this.showChildList3 = false;
      }
      // this.mouseleave = -1;
    },
    onMouseleave(index) {
      // this.isFirst = false;
      console.log(index);
      this.mouseenter = -1;
      this.mouseleave = index;
      if (index == 1) {
        this.showChildList = false;
      }
      if (index == 2) {
        this.showChildList1 = false;
      }
      if (index == 3) {
        this.showChildList2 = false;
      }
      if (index == 5) {
        this.showChildList3 = false;
      }
    },
    onLeave() {
      this.showChildList = false;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.header {
  height: 100px;
  position: fixed;
  // top: 0;
  left: 0;
  width: 100%;
  z-index: 99;

  .nav_content {
    margin: 0 auto;
    height: 100px;
    width: 1200px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .logo {
      width: 180px;
      height: 50px;
      // margin: 0 79px;
    }
    .nav_list {
      -moz-user-select: none; /*火狐*/
      -webkit-user-select: none; /*webkit浏览器*/
      -ms-user-select: none; /*IE10*/
      -khtml-user-select: none; /*早期浏览器*/
      user-select: none;
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 678px;
      border-bottom: 1px solid rgba(216, 216, 216, 0.75);
      .nav {
        // height: 120px;
        cursor: pointer;
        width: 90px;
        font-size: 16px;
        margin-bottom: -2px;
        position: relative;
        .child_list {
          width: 152px;
          height: auto;
          position: absolute;
          background: #fff;
          top: 32px;
          left: -31px;
          .blank {
            height: 12px;
            background: none;
          }
          // .list_main {
          //   // height: 150px;
          //   // padding: 12px 0;
          //   // background: #fff;
          // }
          .item {
            height: 40px;
            font-size: 14px;
            line-height: 40px;
            // margin-bottom: 24px;
          }
          .item:nth-last-child(1) {
            margin-bottom: 0;
          }
          .item:hover {
            color: #2f78ff;
          }
        }
        a {
          width: 90px;
          text-decoration: none;
          // display: flex;
          display: block;
          // justify-content: flex-end;
          // align-items: center;
          // flex-direction: column;
          .text_content {
            width: 90px;
          }
          span {
            display: inline-block;
            // padding-bottom: 20px;
            // margin-top: 20px;
            color: #d7d7d7;
            margin-bottom: 5px;
          }
          .line {
            width: 0;
            height: 3px;
            // background: #d6ac7d;
            background: #fff;
          }
        }
      }
      .nav.active {
        // background: #fafafa;
        a {
          span {
            // border-bottom: 3px solid #d6ac7d;
            // margin-top: 23px;
            color: #fff;
          }

          .line {
            background: #fff;
            width: 100%;
          }

          // border-bottom: 3px solid #d6ac7d;
          // margin-top: 23px;
        }
      }
      .nav:not(.active) {
        .mouseenter {
          // background: #fafafa;
          span {
            // border-bottom: 3px solid #d6ac7d;
            // margin-top: 23px;
            color: #fff;
          }
          // border-bottom: 3px solid #d6ac7d;
          // margin-top: 23px;
          .line {
            animation: lengthen 0.5s;
            -webkit-animation: lengthen 0.5s; /* Safari and Chrome */
            animation-fill-mode: forwards;
          }
        }
      }
      .nav:not(.active) {
        .line.mouseleave {
          animation: shorten 0.5s;
          -webkit-animation: shorten 0.5s;
          animation-fill-mode: forwards;
        }
      }
      @keyframes lengthen {
        from {
          width: 0;
        }
        to {
          width: 100%;
        }
      }

      @-webkit-keyframes lengthen /* Safari 与 Chrome */ {
        from {
          width: 0;
        }
        to {
          width: 100%;
        }
      }

      @keyframes shorten {
        from {
          width: 100%;
        }
        to {
          width: 0;
        }
      }

      @-webkit-keyframes shorten /* Safari 与 Chrome */ {
        from {
          width: 100%;
        }
        to {
          width: 0;
        }
      }
    }
  }
  &.active {
    background: #fff;
    box-shadow: 0px 2px 8px 1px rgba(223, 223, 223, 0.3);
    top: -100px;
    .nav_content {
      .nav_list {
        .nav {
          a {
            span {
              color: #333;
            }
            .line {
              background: #2f78ff;
            }
          }
          &.active {
            // background: #fafafa;
            a {
              span {
                // border-bottom: 3px solid #d6ac7d;
                // margin-top: 23px;
                color: #2f78ff;
              }

              .line {
                background: #2f78ff;
                width: 100%;
              }

              // border-bottom: 3px solid #d6ac7d;
              // margin-top: 23px;
            }
          }
          &:not(.active) {
            .mouseenter {
              // background: #fafafa;
              span {
                // border-bottom: 3px solid #d6ac7d;
                // margin-top: 23px;
                color: #333;
              }
            }
          }
        }
      }
    }
  }
  #activeBg {
    position: absolute;
    background: #fff;
    width: 100%;
    height: 100px;
    top: -100px;
    left: 0;
  }
}
</style>
