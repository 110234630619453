<template>
  <div id="app">
    <!-- <img alt="Vue logo" src="./assets/logo.png"> -->
    <!-- <HelloWorld msg="Welcome to Your Vue.js App"/> -->
    <!-- <router-view></router-view> -->
    <keep-alive v-if="$route.name != 'Home'">
      <router-view v-if="$route.meta.keepAlive">
        <!-- 这里是会被缓存的视图组件，比如 Home！ -->
      </router-view>
    </keep-alive>

    <router-view v-if="!$route.meta.keepAlive">
      <!-- 这里是不被缓存的视图组件，比如 Edit！ -->
    </router-view>
    <!-- <Footer></Footer> -->
  </div>
</template>

<script>
import Header from "./components/header";
import $ from "jquery"

export default {
  name: "App",
  components: {
    // swiper,
    // swiperSlide,
    Header,
    // Footer,
  },
  mounted() {
    console.log(window.yzf);
    // this.$nextTick(() => {
    // setTimeout(() => {
    //参数说明
    //sign：公司渠道唯一标识，复制即可，无需改动
    //uid：用户唯一标识，如果没有则不填写，默认为空
    //data：用于传递用户信息，最多支持5个，参数名分别为c1,c2,c3,c4,c5；默认为空
    //selector：css选择器(document.querySelector, 如#btnid .chat-btn等)，用于替换默认的常驻客服入口
    //callback(type, data): 回调函数,type表示事件类型， data表示事件相关数据
    //type支持的类型：newmsg有新消息，error云智服页面发生错误， close聊天窗口关闭
    window.yzf &&
      window.yzf.init({
        sign:
          "37ef9b97d27c02c120449fed1db4b86ad35cf1936152b2797ddedb7850f98be4b8d5d953e5965cead13bfe5093f550aff150a47a",
        uid: "",
        data: {
          c1: "",
          c2: "",
          c3: "",
          c4: "",
          c5: "",
        },
        selector: "#chat",
        callback: function (type, data) {},
      });
    //window.yzf.close() 关闭1已打开的回话窗口
    // });
    // });
    $('.chat-btn .txt').html('在线咨询')
  },
};
</script>

<style lang="scss">
// html{
//   height: calc(100vh - 120px) !important;
//   margin-top: 120px !important;
// }
html,
body,
div,
h1,
h2,
h3,
h4,
h5,
h6,
p,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
input,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
textarea,
article,
aside,
audio,
canvas,
figure,
footer,
header,
mark,
menu,
nav,
section,
time,
video {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: 100%;
  font-weight: normal;
}
article,
aside,
dialog,
figure,
footer,
header,
hgroup,
nav,
section,
blockquote {
  display: block;
}
ul,
ol {
  list-style: none;
}
img {
  border: 0 none;
  vertical-align: top;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
strong,
em,
i {
  font-style: normal;
  font-weight: normal;
}
ins {
  text-decoration: underline;
}
del {
  text-decoration: line-through;
}
mark {
  background: none;
}
input::-ms-clear {
  display: none !important;
}
body {
  font: 12px/1.5 \5fae\8f6f\96c5\9ed1, \5b8b\4f53, "Hiragino Sans GB", STHeiti,
    "WenQuanYi Micro Hei", "Droid Sans Fallback", SimSun, sans-serif;
  background: #fff;
}
a {
  text-decoration: none;
  color: #333;
}
a:hover {
  text-decoration: underline;
}
body {
  margin: 0;
  padding: 0;
}
// div {
//   // box-sizing: border-box;
// }
#app {
  font-family: PingFang SC-粗体, PingFang SC;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  /* margin-top: 160px; */
  min-width: 1200px;
}

.img_content_pub {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  img {
    width: auto;
    height: 100%;
    // width: 100%;
    // height: 100%;
    object-fit: cover;
  }
}
.banner {
  width: 100%;
  height: 520px;
  // margin-top: 120px;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
.swiper-container-horizontal > .swiper-pagination-bullets,
.swiper-pagination-custom,
.swiper-pagination-fraction {
  display: flex;
  justify-content: center;
  align-items: center;
}
.swiper-pagination-bullet-active {
  width: 12px !important;
  height: 12px !important;
}

.smart_factory {
  padding: 90px 0;
  background: #fff;
  .title {
    font-size: 34px;
    color: #333333;
    // font-weight: bold;
  }
  .subtitle {
    font-size: 14px;
    color: #999999;
    margin-top: 22px;
    margin-bottom: 60px;
  }
  .case {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    .case_introduce {
      width: 400px;
      // display: flex;
      // justify-content: center;
      // align-items: flex-start;
      margin-right: 60px;
      .detail {
        text-align: left;
        .detail_title {
          font-size: 30px;
          color: #0c243d;
          // font-weight: bold;
        }
        .detail_subtitle {
          font-size: 20px;
          color: #999;
          margin-top: 5px;
          margin-bottom: 15px;
        }
        .detail_list_title {
          font-size: 20px;
          color: #2f78ff;
          margin-bottom: 30px;
        }
        .list {
          .item {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            font-size: 16px;
            color: #0d253e;
            margin-bottom: 26px;
            // max-width: calc(100% - 20px);
            .dot_big {
              display: flex;
              justify-content: center;
              align-items: center;
              width: 14px;
              height: 14px;
              border-radius: 100%;
              background: #1fdb84;
              margin-right: 20px;
              flex-shrink: 0;
              &.dot_big_1 {
                background: #2f78ff;
              }
              &.dot_big_2 {
                background: #f78921;
              }
              &.dot_big_3 {
                background: #f72181;
              }
              .dot_small {
                width: 6px;
                height: 6px;
                border-radius: 100%;
                background: #fff;
              }
            }
          }
        }
      }
    }
    .case_img {
      width: 680px;
      img {
        width: 100%;
      }
    }
  }
  .btn {
    margin-top: 85px;
    button {
      width: 176px;
      height: 46px;
      background: #2f78ff;
      border-radius: 6px 6px 6px 6px;
      border: none;
      font-size: 16px;
      color: #fff;
      a {
        color: #fff;
        font-size: 16px;
        text-decoration: none;
      }
    }
  }
}
.smart_city {
  .case_introduce {
    margin-left: 120px;
    margin-right: 0 !important;
  }
  background: #fafbfc;
  .btn {
    button {
      background: #fff;
      border: 1px solid #2f78ff;
      color: #2f78ff;
      a {
        color: #2f78ff;
      }
    }
  }
}
</style>
