import Vue from 'vue'
import Router from 'vue-router'
import Home from './views/home.vue'

Vue.use(Router)




// export default new Router({
//   mode: 'history',
//   routes: 
// })
export const constantRoutes = [
  // {
  //   path: '/',
  //   redirect: '/home'
  // },
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      keepAlive: false // 需要被缓存
    }
  },
  {
    path: '/smartFactory',
    name: 'SmartFactory',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import( /* webpackChunkName: "groupIntroduction" */ './views/smartFactory.vue'),
    meta: {
      keepAlive: false // 需要被缓存
    }
  },
  {
    path: '/smartCity',
    name: 'SmartCity',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import( /* webpackChunkName: "hometownGift" */ './views/smartCity.vue'),
    meta: {
      keepAlive: false // 需要被缓存
    }
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import( /* webpackChunkName: "hometownGift" */ './views/about.vue'),
    meta: {
      keepAlive: false // 需要被缓存
    }
  },
  {
    path: '/contactUs',
    name: 'ContactUs',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import( /* webpackChunkName: "hometownGift" */ './views/contactUs.vue'),
    meta: {
      keepAlive: false // 需要被缓存
    }
  },
]

const createRouter = () => new Router({
  mode: 'history', // require service support
  // scrollBehavior: () => ({
  //   y: 0
  // }),
  routes: constantRoutes
})

const router = createRouter();
// 重置页面滚动条位置
router.afterEach((to, from, next) => {
  if (!to.meta.keepAlive) {
    window.scrollTo(0, 0)
  }
  if (!from.meta.keepAlive) {
    window.scrollTo(0, 0)
  }

})
export default router